import { render, staticRenderFns } from "./QuotationItem.vue?vue&type=template&id=7b161026&scoped=true&"
import script from "./QuotationItem.vue?vue&type=script&lang=js&"
export * from "./QuotationItem.vue?vue&type=script&lang=js&"
import style0 from "./QuotationItem.vue?vue&type=style&index=0&id=7b161026&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b161026",
  null
  
)

export default component.exports